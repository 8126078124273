@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
}